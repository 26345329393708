<template>
    <div class="datetimepicker-container">
        <date-time-picker v-model="dateDebutInput"
                          :min-datetime="minDate"
                          :max-datetime="dateFinInput || maxDate"/>

        <font-awesome-icon icon="long-arrow-alt-right" class="arrow-icon"/>

        <date-time-picker v-model="dateFinInput"
                          :min-datetime="dateDebutInput || minDate"
                          :max-datetime="maxDate"
                          no-button-now/>
    </div>
</template>

<script>
    const DateTimePicker = () => import('@/components/DateTimePicker');

    import {makeMoment} from '@/util/date';

    export default {
        name: "TwoDatesPicker",
        components: {DateTimePicker},
        props: {
            dateDebut: {
                default: null
            },
            dateFin: {
                default: null
            },
            minDate: {
                default: new Date().toISOString(),
            },
            maxDate: {
                default: null
            }
        },
        data: () => ({
            dateDebutInput: null,
            dateFinInput: null
        }),
        watch: {
            dateDebutInput(val) {
                this.dateDebutInput = val;
                this.$emit('dateDebutChange', val);
            },
            dateFinInput(val) {
                this.dateFinInput = val;
                this.$emit('dateFinChange', val);
            }
        },
        created() {
            this.dateDebutInput = this.dateDebut ? makeMoment(this.dateDebut).toISOString(true) : null;
            this.dateFinInput   = this.dateFin ? makeMoment(this.dateFin).toISOString(true) : null;
        }
    }
</script>

<style scoped>
    .datetimepicker-container {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
    }

    @media (min-width: 768px) {
        .datetimepicker-container {
            flex-direction: row;
        }
    }

    .arrow-icon {
        height: 30px;
        width: auto;
        margin: 5px 20px;
    }
</style>
